import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"

export default function Home({ data }) {
  console.log(data)

  const [currentFilter, setFilter] = useState("All")

  return (
    <Layout about={false} onFilterChange={setFilter}>
      <div
        style={{
          margin: "3rem auto",
          display: "flex",
          flexWrap: "wrap",
          alignContent: "flex-start",
          justifyContent: "space-between",
        }}
      >
        {data.allMarkdownRemark.edges
          .filter(
            ({ node }) =>
              currentFilter === "All" || node.frontmatter.tag === currentFilter
          )
          .map(({ node }) => (
            <>
              <Image
                src={node.frontmatter.image.publicURL}
                title={node.frontmatter.title}
                subtitle={node.frontmatter.subtitle}
                date={node.frontmatter.date}
              />
            </>
          ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            subtitle
            tag
            date(formatString: "MM.YY")
            image {
              publicURL
            }
          }
          excerpt
        }
      }
    }
  }
`
